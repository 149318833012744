import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'; // Gestion des cookies
import CryptoJS from 'crypto-js'; // Import de crypto-js pour le chiffrement
import './App.css';

const encodedTirage = "eyJMdWNhcyI6ICJNYXRoaWV1IiwgIk1hdGhpZXUiOiAiQWxhaW4iLCAiQWxhaW4iOiAiVmlyZ2lsZSIsICJWaXJnaWxlIjogIk1heGVuY2UiLCAiTWF4ZW5jZSI6ICJTdGVwaGFuZSIsICJTdGVwaGFuZSI6ICJWaXJnaW5pZSIsICJWaXJnaW5pZSI6ICJQYXRyaWNlIiwgIlBhdHJpY2UiOiAiQXhlbCIsICJBeGVsIjogIk5hdGhhbGlhIiwgIk5hdGhhbGlhIjogIlZpY3RvcmluZSIsICJWaWN0b3JpbmUiOiAiQ2FuZGljZSIsICJDYW5kaWNlIjogIlBoaWxpcHBpbmUiLCAiUGhpbGlwcGluZSI6ICJBbm5lIiwgIkFubmUiOiAiTWFyaW5lIiwgIk1hcmluZSI6ICJBbGV4YW5kcmUiLCAiQWxleGFuZHJlIjogIkd1aWxsYXVtZSIsICJHdWlsbGF1bWUiOiAiTHVjYXMifQ=="; // Remplacé par XXX

// Contenu obfusqué du showOverlay
const secretKey = "superSecretKey123"; // Clé secrète pour le chiffrement/déchiffrement
const encryptedMessage = CryptoJS.AES.encrypt(
  `  <div class="mystery-container">
      <p class="mystery-text">
        "Il est murmuré dans les couloirs du temps qu'un secret oublié repose entre les ombres du passé. 
        Seul celui qui fit couler le sang des Cathares, portant le fardeau d'une croix tordue et d'une épée ardente, 
        pourra ouvrir la voie vers la vérité cachée. Cherchez son nom dans l'histoire, et il vous guidera."
      </p>
      <p class="mystery-link">zbfdf://ycuzyscyszgvftbjb.rf/TzwehEwkafqwl.ioi</p>
    </div>`,
  secretKey
).toString(); // Message chiffré

function App() {
  const [nom, setNom] = useState('');
  const [resultat, setResultat] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [isGlitching, setIsGlitching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
let tirage = {};
try {
  tirage = JSON.parse(atob(encodedTirage));
} catch (e) {
  console.error("Erreur lors du parsing de encodedTirage :", e);
}


  const handleSubmit = (e) => {
    e.preventDefault();
    const currentToken = Cookies.get('userToken');

    if (currentToken && currentToken !== nom) {
      setErrorMessage("Vous n'êtes pas autorisé à regarder un autre nom.");
      return;
    }

    if (tirage[nom]) {
      Cookies.set('userToken', nom, { expires: 1 });
      setResultat(`Vous avez tiré : ${tirage[nom]}`);
      setErrorMessage('');
    } else {
      setResultat("Nom non trouvé dans le tirage.");
    }
  };

  // Fonction pour déchiffrer le message uniquement lorsque l'overlay doit s'afficher
  const decryptOverlayMessage = () => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  };

 useEffect(() => {
  let snowflakeCount = 0; // Compteur de flocons visibles
  const maxSnowflakes = 25; // Limite maximale de flocons simultanés

  const createSnowflakes = () => {
    if (snowflakeCount >= maxSnowflakes) return; // Ne crée pas de flocons supplémentaires

    const snowflake = document.createElement("div");
    snowflake.className = Math.random() < 0.15 ? "leaf" : "snowflake";
    snowflake.innerText = snowflake.className === "leaf" ? "🍻" : "❄️";
    snowflake.style.left = `${Math.random() * 100}vw`;
    snowflake.style.animationDuration = `${Math.random() * 3 + 2}s`;

    // Incrémente le compteur lors de la création
    snowflakeCount++;

    snowflake.addEventListener("click", () => {
      setIsGlitching(true);
      setTimeout(() => setIsGlitching(false), 1000);
      setTimeout(() => setShowOverlay(true), 1000);
      snowflake.remove();
      snowflakeCount--; // Décrémente le compteur lorsqu'il est cliqué
    });

    document.body.appendChild(snowflake);

    snowflake.addEventListener("animationend", () => {
      snowflake.remove();
      snowflakeCount--; // Décrémente le compteur lorsque l'animation se termine
    });
  };

  const snowflakeInterval = setInterval(createSnowflakes, 900);

  return () => clearInterval(snowflakeInterval); // Nettoie l'intervalle lorsque le composant est démonté
}, []);

  return (
    <div className={`App ${isGlitching ? 'glitch' : ''}`}>
      <h1>🎄 Montfort Santa 🎄</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Entrez votre nom"
          value={nom}
          onChange={(e) => setNom(e.target.value)}
        />
        <button type="submit">Voir le tirage</button>
      </form>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {resultat && <p>{resultat}</p>}
      
      {/* Affichage du message déchiffré uniquement si showOverlay est activé */}
      {showOverlay && (
        <div className="overlay" dangerouslySetInnerHTML={{ __html: decryptOverlayMessage() }} />
      )}
    </div>
  );
}

export default App;
